.panel {
  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }

  li {
    list-style: none;
    margin-bottom: $spacing-unit-small;

    &:last-child {
      margin-bottom: 0;
    }
  }

  padding: 0 15px;
}

.panel--default {
  background-color: $panel-bg-color;

  &, & h1, & h2, & h3 {
    color: $panel-text-color;
  }
}

.panel--important {
  background-color: $panel-important-bg-color;
}

.panel--important, .panel--alt {
  margin-bottom: $spacing-unit-small;
  padding: $spacing-unit-small;
}

.panel--alt {
  background-color: $panel-alt-bg-color;
}
