$ms-base: 1em;
$ms-ratio: 1.4;

@font-face {
  font-family: Archer;
  src: url('../../fonts/ArcherPro-Medium.otf');
}

@font-face {
  font-family: ArcherSemiBold;
  src: url('../../fonts/ArcherPro-Semibold.otf');
}

body {
  font-family: $font-family-default;
  color: $text-color;
}

a {
  color: $brand-color;
  text-decoration: none;
  font-weight: 600;
  transition: color .1s ease;
}

a:hover {
  color: lighten($brand-color, 10%);
}

strong, b {
  font-weight: 600;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $font-family-title;
  line-height: 110%;
}

h1, .h1 {
  font-size: ms(3) * 0.75;
  margin-bottom: 0.4em;
  color: $h1-color;

  @include media('md') {
    font-size: ms(3);
  }
}

h2, .h2 {
  font-size: ms(2);
  margin-top: 0.8em;
  margin-bottom: 0.4em;
  color: $h2-color;
}

h3, .h3 {
  font-size: ms(1);
  color: $h3-color;
}

h4, .h4 {
  font-size: ms(0);
  color: $h4-color;
}

.title-secondary {
  color: $title-secondary-color;
  font-family: $font-family-title-semibold;
  font-weight: $font-family-title-semibold-weight;
  font-size: $title-secondary-size;
}

p {
  margin-top: 0.8em;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
