.slideout {
  color: #eee;

  h2 {
    margin-left: 10px;
  }
}

.slideout-menu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: 256px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: none;
  background-color: #333;
}

.slideout-panel {
  position: relative;
  z-index: 1;
  background-color: white;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-menu {
  display: block;
}

body {
  height: 100%;
  width: 100%;
}

.hamburger {
  cursor: pointer;
  padding: 10px 35px 16px 0;
  display: inline-block;
}

.hamburger span, .hamburger span:before, .hamburger span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 5px;
  width: 35px;
  background: #aaa;
  position: absolute;
  display: block;
  content: '';
}
.hamburger span:before {
  top: -10px;
}
.hamburger span:after {
  bottom: -10px; 
}

