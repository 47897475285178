.menu {
  margin: 0;
  padding: 0;
}
  .menu__item {
    margin-bottom: 15px;
    list-style-type: none;

    font-weight: bold;
    text-transform: uppercase;

    a {
      text-decoration: none;
      display: block;
    }
  }

  .menu__item--active {
    font-weight: normal;
  }

  .menu--horizontal .menu__item {
    margin-bottom: 0;
    padding-right: 10px;
    display: inline-block;
  }

    .menu__item__image {
      display: block;
      opacity: 0.5;
    }

    .menu__item--active .menu__item__image, .menu__item:hover .menu__item__image {
      opacity: 1;
    }

.menu--mobile {
  color: #ddd;

  .menu__item {
    margin: 0;
    text-transform: none;

    a {
      display: block;
      padding: 10px;
      border-bottom: 1px solid darken($brand-color, 20%);
      font-weight: bold;
    }
  }

  .menu__item:last-child a {
    border-bottom: none;
  }

  .menu__item--active {
    background-color: $brand-color;

    a {
      color: #333;
    }
  }
}
