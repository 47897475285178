.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex--center {
  justify-content: space-between;
}

.flex--left {
  justify-content: flex-start;
}

.flex__item {
  display: inline-block;
  vertical-align: top;
}
