@media (min-width: 400px) {
  .multi-column {
    column-count: 2;
  }
}

// Fix to avoid breaks inside elements
.multi-column > * {
  display: inline-block;
  width: 100%;
}
