/*----------------------------------------*\
  GLOBAL SETTINGS
\*----------------------------------------*/

// Use this setting to prefix all the components classes
// $namespace:                         '';


/**
 * Colors
 */

$brand-color:                       #d22;
$alt-brand-color:                   #83FF8F;
$brand-color-light: lighten($brand-color, 10%);
$brand-color-lighter: lighten($brand-color, 20%);

$white:                             #fff;
$alt-color-lighter:                 #eee;
$alt-color-light:                   #999;
$alt-color:                         #777;
$alt-color-dark:                    #555;
$alt-color-darker:                  #333;
$black:                             #000;

$success:                           #DFF0D8;
$success-text:                      darken($success, 50%);
$info:                              #D9EDF7;
$info-text:                         darken($info ,50%);
$warning:                           #FCF8E3;
$warning-text:                      darken($warning, 50%);
$error:                             #C77070;
$error-text:                        darken($error, 50%);

$panel-bg-color: $brand-color;
$panel-text-color: $white;
$panel-important-bg-color: lighten($brand-color, 40%);
$panel-alt-bg-color: lighten($panel-important-bg-color, 5%);

/**
 * Typography
 */

$font-family-default:               "Open Sans",Helvetica,Arial,sans-serif;
$font-family-title: 'Roboto', sans-serif;
$font-family-title-semibold: $font-family-title;
$font-family-title-semibold-weight: 600;

$font-size-default:                 16px;
$font-size-small:                   0.75rem;
$font-size-large:                   1.5rem;
$font-size-huge:                    2rem;

// $line-height-default:               1.45;

$text-color:                        #222;
$link-color:                        $black;
// $muted-color:                       transparentize($text-color,.4);

$h1-color: $brand-color;
$h2-color: $text-color;
$h3-color: $text-color;
$h4-color: $text-color;
$title-secondary-color: $brand-color;
$title-secondary-size: 20px;


/**
 * Spacings
 */

// $ratio:                             1.61803398875;

// $spacing-unit-default:              24px;
// $spacing-unit-tiny:                 floor($spacing-unit-default / ($ratio * 2));
// $spacing-unit-small:                floor($spacing-unit-default / $ratio);
// $spacing-unit-large:                floor($spacing-unit-default * $ratio);
// $spacing-unit-huge:                 floor($spacing-unit-default * ($ratio * 2));


/**
 * Responsiveness
 */

$screen-xs-masonry:                 640px;
$screen-md-masonry:                 1100px;
$screen-lg-masonry:                 2000px;

$screen-lg-layout:                  1500px;

// $screen-xs-max:                     640px;
// $screen-sm-min:                     $screen-xs-max + 1px;
// $screen-sm-max:                     768px;
// $screen-md-min:                     $screen-sm-max + 1px;
// $screen-md-max:                     1024px;
// $screen-lg-min:                     $screen-md-max + 1px;

// $breakpoints-default:               (
//                                       'sm' '(min-width: #{$screen-sm-min})',
//                                       'md' '(min-width: #{$screen-md-min})',
//                                       'lg' '(min-width: #{$screen-lg-min})'
//                                     );
// $breakpoints-desc:                  (
//                                       'xs' '(max-width: #{$screen-xs-max})',
//                                       'sm' '(max-width: #{$screen-sm-max})',
//                                       'md' '(max-width: #{$screen-md-max})'
//                                     );


/**
 * Miscellaneous
 */

// $border-radius-default:             4px !default;
// $border-radius-small:               2px !default;
// $border-radius-large:               6px !default;

$border-radius-default:             0px;
$border-radius-small:               0px;
$border-radius-large:               0px;


/*----------------------------------------*\
  COMPONENTS
\*----------------------------------------*/

/**
 * Buttons
 */

// $btn-padding:                       $spacing-unit-small;
// $btn-color:                         inherit;
// $btn-border:                        1px solid transparent;
// $btn-bkg:                           transparent;
// $btn-border-radius:                 $border-radius-default;

// $btn-small-padding:                 $spacing-unit-tiny;
// $btn-small-font-size:               $font-size-small;
// $btn-small-border-radius:           $border-radius-small;

// $btn-large-padding:                 $spacing-unit-default;
// $btn-large-font-size:               $font-size-large;
// $btn-large-border-radius:           $border-radius-large;

// $btn-default-color:                 $text-color;
// $btn-default-bkg-color:             $alt-color-lighter;
// $btn-default-hover-bkg-color:       lighten($btn-default-bkg-color, 3%);
// $btn-default-active-bkg-color:      darken($btn-default-bkg-color, 5%);

// $btn-primary-color:                 white;
// $btn-primary-bkg-color:             $brand-color;
// $btn-primary-hover-bkg-color:       lighten($btn-primary-bkg-color, 8%);
// $btn-primary-active-bkg-color:      darken($btn-primary-bkg-color, 5%);


/**
 * Forms
 */

// $field-padding:                     $spacing-unit-small;

// $field-color:                       inherit;
// $field-font-size:                   inherit;
// $field-font-family:                 inherit;
// $field-line-height:                 $line-height-default;

// $field-bkg-color:                   white;
// $field-border:                      1px solid $alt-color-light;
// $field-focus-border-color:          $alt-color-darker;
// $field-border-radius:               $border-radius-default;

// $field-disabled-bkg-color:          $alt-color-lighter;
// $field-disabled-color:              $alt-color;

// $field-small-padding:               $spacing-unit-tiny;
// $field-small-font-size:             $font-size-small;
// $field-small-border-radius:         $border-radius-small;

// $field-large-padding:               $spacing-unit-default;
// $field-large-font-size:             $font-size-large;
// $field-large-border-radius:         $border-radius-large;

// $field-help-color:                  $alt-color-light;


/**
 * Lists
 */

// $list-separator-style:              1px solid $alt-color-lighter;


/**
 * Box
 */

// $box-default-color:                 inherit;
// $box-default-bkg-color:             $alt-color-lighter;

// $box-primary-color:                 white;
// $box-primary-bkg-color:             $brand-color;


/**
 * Widths
 */

// $widths-columns:                    6,5,4,3,2,1;
// $widths-breakpoints:                $breakpoints-default;


/**
 * Media
 */

// $media-gutter:                      $spacing-unit-default;


/**
 * Media responsive
 */

// $media-collapse:                    $screen-sm-max;


/**
 * Mosaic responsive
 */

// $mosaic-collapse:                   $screen-sm-max;
