.pswp--zoomed-in .pswp__caption {
  display: none;
}

.gallery-item__title {
  font-size: 1.6em;
}

/* Fix space below thumbnail */
.gallery-item__thumbnail {
  display: block;
}
