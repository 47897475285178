.video {
  position: relative;
}

.video:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background-image: url('../../images/icons/play.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20%;
}
